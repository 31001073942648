export const checkActive = (
  pathname: string,
  url: string,
  postType: string,
) => {
  if (postType === "Post") {
    return pathname === `/posts${url}`;
  }
  return pathname === url;
};
