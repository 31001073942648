"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import type { IMenu } from "@/types/wordpress-types";
import { checkActive } from "@/helpers/checkActiveLink";

type Props = {
  menuData: IMenu;
};
export function DesktopMainMenu({ menuData }: Props) {
  const pathname = usePathname();

  return (
    <>
      <ul
        id="menu-header"
        className="menu flex h-full w-6/12 grow-1 shrink-0 tw-items-center border-r border-solid border-pn-black-base"
      >
        {menuData.menu_items.map((menuItem, index) => {
          const isActive = checkActive(pathname, menuItem.url, menuItem.object);

          return (
            <li
              key={menuItem.ID}
              className={`h-full flex items-center justify-center overflow-hidden flex-1 text-center menu-item menu-item-type-${menuItem.type} menu-item-object-${menuItem.object}`}
            >
              <Link
                className={`font-medium h-full flex items-center 
                justify-center uppercase pt-0.5 w-full ${isActive ? "active" : ""}`}
                href={menuItem.url}
              >
                {menuItem.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}
