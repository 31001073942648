"use client";

import React, { SetStateAction, useMemo, type PropsWithChildren } from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "./cookieConsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { GoogleTagManager } from "@next/third-parties/google";

declare const window: Window & {
  dataLayer: Record<string, unknown>[];
  _ccRun: boolean;
};

const updateCookieConsent = () => {
  CookieConsent.showPreferences();
};

const resetCookieConsent = () => {
  CookieConsent.reset(true);
};

/**
 * Initialize Google Analytics with default consent settings
 */
const initializeGtagWithDefaultConsent = (gtmId: string) => {
  if (window._ccRun) return;

  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any) {
    window.dataLayer.push(args);
  }

  gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "granted",
  });

  gtag("js", new Date());
  gtag("config", gtmId);

  window.gtag = gtag;
};

const updateGtagConsent = (
  setLoadScript: React.Dispatch<SetStateAction<boolean>>,
) => {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any) {
    window.dataLayer.push(args);
  }

  setLoadScript(true);

  gtag("consent", "update", {
    ad_storage: CookieConsent.acceptedCategory("advertisement")
      ? "granted"
      : "denied",
    ad_user_data: CookieConsent.acceptedCategory("advertisement")
      ? "granted"
      : "denied",
    ad_personalization: CookieConsent.acceptedCategory("advertisement")
      ? "granted"
      : "denied",
    analytics_storage: CookieConsent.acceptedCategory("analytics")
      ? "granted"
      : "denied",
    functionality_storage: CookieConsent.acceptedCategory("necessary")
      ? "granted"
      : "denied",
    personalization_storage: CookieConsent.acceptedCategory("necessary")
      ? "granted"
      : "denied",
    security_storage: CookieConsent.acceptedCategory("necessary"),
  });
};

type Props = {
  gtmId: string;
};

export const CookieConsentProvider = ({
  gtmId,
  children,
}: PropsWithChildren<Props>) => {
  const [loadScript, setLoadScript] = React.useState(false);

  React.useEffect(() => {
    if (!gtmId) {
      return;
    }

    initializeGtagWithDefaultConsent(gtmId);

    CookieConsent.run({
      onConsent: ({ cookie }) => {
        updateGtagConsent(setLoadScript);
      },
      onChange: () => updateGtagConsent(setLoadScript),

      disablePageInteraction: false,
      guiOptions: {
        consentModal: {
          layout: "box wide",
          position: "bottom right",
        },
        preferencesModal: {
          layout: "box",
          flipButtons: false,
          equalWeightButtons: true,
        },
      },
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },

      language: {
        default: "en",
        translations: {
          en: {
            consentModal: {
              title: "We use cookies",
              description:
                "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage and assist in our marketing efforts.",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Configure",
              closeIconLabel: "X",
            },
            preferencesModal: {
              title: "Manage Cookie Consent",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              sections: [
                {
                  title: "Cookie Usage",
                  description: `Cookies are small text files which are transferred to your computer or mobile when you visit a website or app. To provide the best experiences, we use technologies like cookies to store and/or access device information. Consenting to these technologies will allow us to process data such as browsing behaviour or unique IDs on this site. Not consenting, or withdrawing consent, may adversely affect certain features and functions of this website.<br><br>
                  Find more information in our <a href="/privacy-policy">Privacy Policy.`,
                },
                {
                  title: "Functional",
                  description:
                    "The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the transmission of a communication over an electronic communications network.",

                  // This field will generate a toggle linked to the 'necessary' category
                  // !!! To check whether this category is enabled, use `CookieConsent.acceptedCategory('necessary')`
                  // CookieConsent will not update value of the 'functional' category
                  linkedCategory: "necessary",
                },
                {
                  title: "Performance and Analytics",
                  description:
                    "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                },
                {
                  title: "More information",
                  description:
                    'For any queries in relation to my policy on cookies and your choices, please <a href="/contact">contact us</a>',
                },
              ],
            },
          },
        },
      },
    });
  }, [setLoadScript, gtmId]);

  return (
    <>
      {loadScript && <GoogleTagManager gtmId={gtmId} />}
      {children}
    </>
  );
};
