"use client";

import { SignInPopover } from "../auth/SignInPopover";
import React from "react";
import { useSignInPopoverStore } from "../providers/SingInPopoverStoreProvider";
import { useHamburgerMenuStore } from "../providers/HamburgerMenuProvider";

export function UserDesktopButton() {
  const { open, setOpen } = useSignInPopoverStore((state) => state);
  const setHamburgerMenuOpen = useHamburgerMenuStore((state) => state.setOpen);

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(!open);

    /**
     * If it was closed, it is going to open, close hamburger menu
     */
    if (open === false) {
      setHamburgerMenuOpen(false);
    }
  };

  const popOverRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // For external buttons that open the popover
      // Check if the clicked element has the class 'no-close-popover'
      if (target.closest(".no-close-popover")) {
        return; // Do nothing if the element has the class
      }

      // We only want to close the popover if the user clicks outside the popover
      // on desktop
      if (
        popOverRef.current &&
        !popOverRef.current.contains(target) &&
        window.innerWidth >= 1024
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [setOpen]);

  return (
    <div className="relative" ref={popOverRef}>
      <button className="btn btn-secondary max-h-12" onClick={handleOpen}>
        Register / Sign in
      </button>
      <SignInPopover />
    </div>
  );
}
