import { createStore } from "zustand/vanilla";

export type HamburgerMenuState = {
  open: boolean;
};

export type HamburgerMenuActions = {
  setOpen: (open: boolean) => void;
};

export type HamburgerMenuStore = HamburgerMenuState & HamburgerMenuActions;

export const defaultInitialState: HamburgerMenuState = {
  open: false,
};

export const createHamburgerMenuStore = (
  initalState: HamburgerMenuState = defaultInitialState,
) => {
  return createStore<HamburgerMenuStore>((set) => ({
    ...initalState,
    setOpen: (open) => set({ open }),
  }));
};
