"use client";

import {
  createHamburgerMenuStore,
  type HamburgerMenuStore,
} from "@/stores/hamburgerMenuStore";
import { type ReactNode, createContext, useRef, useContext } from "react";
import { type StoreApi, useStore } from "zustand";

const HamburgerMenuStoreContext =
  createContext<StoreApi<HamburgerMenuStore> | null>(null);

export type HamburgerMenuStoreProviderProps = {
  children: ReactNode;
};

export const HamburgerMenuStoreProvider = ({
  children,
}: HamburgerMenuStoreProviderProps) => {
  const storeRef = useRef(createHamburgerMenuStore());
  if (!storeRef.current) {
    storeRef.current = createHamburgerMenuStore();
  }
  return (
    <HamburgerMenuStoreContext.Provider value={storeRef.current}>
      {children}
    </HamburgerMenuStoreContext.Provider>
  );
};

export const useHamburgerMenuStore = <T,>(
  selector: (store: HamburgerMenuStore) => T,
): T => {
  const hamburgerMenuStoreContext = useContext(HamburgerMenuStoreContext);
  if (!hamburgerMenuStoreContext) {
    throw new Error(
      "useHamburgerMenuStore must be used within a HamburgerMenuStoreProvider",
    );
  }
  return useStore(hamburgerMenuStoreContext, selector);
};
