"use client";

import { useSignInPopoverStore } from "@/components/providers/SingInPopoverStoreProvider";
import React from "react";
import { Steps } from "./steps";
import Link from "next/link";
import { AppleSignInForm } from "../AppleSignInForm";
import { GoogleSignInForm } from "../GoogleSignInForm";
import Image from "next/image";
import { SignInFormMobile } from "./SignInFormMobile";
import { MfaForm } from "../SignInPopover/MfaForm";
import { ResetPasswordForm } from "../SignInPopover/ResetPasswordForm";
import { ResetPasswordVerificationForm } from "../SignInPopover/ResetPasswordVerificationForm";
import { UpdatePasswordForm } from "../SignInPopover/UpdatePasswordForm";
import { useGetUser } from "@/hooks/useGetUser";
import { usePathname } from "next/navigation";

type Props = {};

export const SignInOverlayMobile = (props: Props) => {
  const { open, setOpen } = useSignInPopoverStore((state) => state);
  const { data: userData } = useGetUser();

  const pathname = usePathname();

  const [step, setStep] = React.useState<(typeof Steps)[keyof typeof Steps]>(
    Steps.default
  );

  const [sessionID, setSessionID] = React.useState<string | null>(null);
  const [email, setEmail] = React.useState<string | null>(null);

  // We need password temporarily for resend functionality
  const [password, setPassword] = React.useState<string | null>(null);

  // Reset password code
  const [resetCode, setResetCode] = React.useState<string | null>(null);

  // Don't open popover if the user is logged in
  if (!open || userData?.user) {
    return <></>;
  }

  const resetToEmailSignIn = () => {
    setStep(Steps.emailSignIn);
    setEmail(null);
    setSessionID(null);
    setPassword(null);
    setResetCode(null);
  };

  const resetFlow = () => {
    setStep(Steps.emailSignIn);
    setEmail(null);
    setSessionID(null);
    setPassword(null);
    setResetCode(null);
  };

  if (step === Steps.updatePassword) {
    return (
      <div className="absolute lg:hidden top-full border-t-[0.5px] pt-5 pb-10 border-pn-black-base left-0s w-screen h-screen bg-pn-white-base">
        <div className="wrapper flex flex-col space-y-5 justify-center items-start self-stretch">
          <div className="w-full flex justify-end">
            <button className="w-6 h-6" onClick={() => setOpen(false)}>
              <Image
                src="/icons/close-small.svg"
                alt="Close"
                width={14}
                height={14}
              />
            </button>
          </div>
          <h3 className="h1-mobile-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Create a new password
          </h3>

          <p className="small-print text-pn-black-base">
            Set a password for your Photography News account: <br />
            <strong className="small-print-bold">{email}</strong>
          </p>

          <UpdatePasswordForm
            email={email!}
            resetFlow={resetToEmailSignIn}
            code={resetCode!}
          />
        </div>
      </div>
    );
  }

  if (step === Steps.resetPasswordVerification) {
    return (
      <div className="absolute lg:hidden top-full border-t-[0.5px] pt-5 pb-10 border-pn-black-base left-0s w-screen h-screen bg-pn-white-base">
        <div className="wrapper flex flex-col space-y-5 justify-center items-start self-stretch">
          <div className="w-full flex justify-end">
            <button className="w-6 h-6" onClick={() => setOpen(false)}>
              <Image
                src="/icons/close-small.svg"
                alt="Close"
                width={14}
                height={14}
              />
            </button>
          </div>
          <h3 className="h1-mobile-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Check your email inbox
          </h3>

          <p className="small-print text-pn-black-base">
            We’ve sent an email to{" "}
            <strong className="small-print-bold">{email}</strong> with a
            verification code
          </p>

          <ResetPasswordVerificationForm
            email={email!}
            setStep={setStep}
            setCode={setResetCode}
          />
        </div>
      </div>
    );
  }

  if (step === Steps.resetPassword) {
    return (
      <div className="absolute lg:hidden top-full border-t-[0.5px] pt-5 pb-10 border-pn-black-base left-0s w-screen h-screen bg-pn-white-base">
        <div className="wrapper flex flex-col space-y-5 justify-center items-start self-stretch">
          <div className="w-full flex justify-end">
            <button className="w-6 h-6" onClick={() => setOpen(false)}>
              <Image
                src="/icons/close-small.svg"
                alt="Close"
                width={14}
                height={14}
              />
            </button>
          </div>
          <h3 className="h1-mobile-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Reset password
          </h3>

          <p className="small-print text-pn-black-base">
            Please enter the email address linked to your account
          </p>

          <ResetPasswordForm
            resetFlow={resetFlow}
            setEmail={setEmail}
            setStep={setStep}
          />
        </div>
      </div>
    );
  }

  if (step === Steps.emailSignIn) {
    return (
      <div className="absolute lg:hidden top-full border-t-[0.5px] pt-5 pb-10 border-pn-black-base left-0s w-screen h-screen bg-pn-white-base">
        <div className="wrapper flex flex-col space-y-5 justify-center items-start self-stretch">
          <div className="w-full flex justify-end">
            <button className="w-6 h-6" onClick={() => setOpen(false)}>
              <Image
                src="/icons/close-small.svg"
                alt="Close"
                width={14}
                height={14}
              />
            </button>
          </div>
          <h3 className="h1-mobile-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
            Sign in
          </h3>

          <SignInFormMobile
            setEmail={setEmail}
            setSessionID={setSessionID}
            setPassword={setPassword}
            setStep={setStep}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="absolute lg:hidden top-full border-t-[0.5px] pt-5 pb-10 border-pn-black-base left-0s w-screen h-screen bg-pn-white-base">
      <div className="wrapper flex flex-col space-y-5 justify-center items-start self-stretch">
        <div className="w-full flex justify-end">
          <button className="w-6 h-6" onClick={() => setOpen(false)}>
            <Image
              src="/icons/close-small.svg"
              alt="Close"
              width={14}
              height={14}
            />
          </button>
        </div>
        <h3 className="h1-mobile-light text-pn-black-base pb-5 w-full text-left border-b-[0.5px] border-pn-black-base self-stretch">
          Sign in
        </h3>

        <GoogleSignInForm />
        <AppleSignInForm />

        <button
          className="flex justify-center items-center self-stretch h-[3.125rem] space-x-[0.625rem]
        rounded-[1.5625rem] border border-solid border-pn-black-base hover:bg-pn-white-hover transition-all ease-in-expo"
          onClick={() => setStep(Steps.emailSignIn)}
        >
          <Image
            src="/icons/email.svg"
            alt="Email"
            width={24}
            height={24}
            sizes="24px"
          />
          <p className="text-pn-black-base paragraph-bold">
            Continue with email
          </p>
        </button>

        <p className="small-print text-pn-black-base">
          Don&apos;t have an account?{" "}
          <Link
            href={`/register?redirect=${pathname}`}
            onClick={() => setOpen(false)}
            className="paragraph-link text-pn-black-base"
          >
            Register
          </Link>{" "}
          for Photography News, it’s free!
        </p>
      </div>
    </div>
  );
};
