"use client";

import { IUser } from "@/types/User";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import React from "react";
import { Steps } from "./steps";

type ResetPasswordFormProps = {
  setEmail: (email: string) => void;
  setStep: (step: (typeof Steps)[keyof typeof Steps]) => void;
  resetFlow: () => void;
};

type ResetPasswordFormValues = {
  email: string;
};

type ResetPasswordResponse = {
  error?: string;
  user?: IUser;
};

const resetPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
});

export const ResetPasswordForm = ({
  resetFlow,
  setEmail,
  setStep,
}: ResetPasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [apiError, setApiError] = React.useState<string | null>(null);

  const onSubmit: SubmitHandler<ResetPasswordFormValues> = async (data) => {
    setIsSubmitting(true);

    const resetPasswordEndpoint = `/api/auth/reset-password`;

    try {
      const response = await fetch(resetPasswordEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // We can store the response in a global state or context and local storage with expiry
      if (response.status < 400) {
        const resetPasswordResponse =
          (await response.json()) as ResetPasswordResponse;

        setIsSubmitting(false);
        setEmail(data.email);
        setStep(Steps.resetPasswordVerification);
      } else {
        console.error("Failed to send reset password request");
        setApiError("Failed to send reset password email");

        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);

      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-start space-y-5 w-full"
    >
      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="email" className="text-pn-black-base">
          Email
        </label>
        <input
          type="text"
          id="email"
          {...register("email")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`,
          )}
          placeholder="Email address"
        />
        {errors.email && (
          <p className="text-pn-red-base">{errors.email.message}</p>
        )}
      </div>

      <div className="flex p-5 bg-pn-off-white-base text-pn-black-base w-full">
        <span className="small-print">Changed your mind?</span>
        <button
          type="button"
          className="small-print-link px-[2px]"
          onClick={resetFlow}
        >
          Go back
        </button>
      </div>

      <button
        type="submit"
        className="w-full h-[3.125rem] rounded-[1.5625rem] border border-pn-black-base text-pn-black-base bg-pn-white-base
          hover:bg-pn-white-hover disabled:bg-pn-white-hover"
        disabled={isSubmitting}
      >
        Next
      </button>

      {apiError && <p className="text-pn-red-base">{apiError}</p>}
    </form>
  );
};
