import { useEffect, useState } from "react";
import { ICompany } from "@/types/wordpress-types";

export function useCompanyData() {
  const [companyData, setCompanyData] = useState<ICompany | null>(null);

  useEffect(() => {
    async function fetchPosts() {
      let res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/wp-json/bright/v1/company`,
      );
      let data = await res.json();
      setCompanyData(data);
    }

    fetchPosts();
  }, []);

  return companyData;
}
