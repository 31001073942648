"use client";

import Image from "next/image";
import { useSignInPopoverStore } from "../providers/SingInPopoverStoreProvider";
import { useHamburgerMenuStore } from "../providers/HamburgerMenuProvider";
import { useGetUser } from "@/hooks/useGetUser";
import { useUserSettingsPopoverStore } from "../providers/UserSettingsPopoverStoreProvider";
import { UserSettingsPopover } from "../auth/UserSettingsPopover";

export function UserIconButton() {
  const { open, setOpen } = useSignInPopoverStore((state) => state);
  const setHamburgerMenuOpen = useHamburgerMenuStore((state) => state.setOpen);
  const { open: settingsPopoverOpen, setOpen: setSettingsPopoverOpen } =
    useUserSettingsPopoverStore((state) => state);
  const { data } = useGetUser();

  const handleOpen = () => {
    /**
     * It was closed and going to open, close hamburger menu
     */
    if (open === false) {
      setHamburgerMenuOpen(false);
    }

    // If the user is logged in, open user settings popover instead
    if (data?.user) {
      setSettingsPopoverOpen(!settingsPopoverOpen);
    }

    setOpen(!open);
  };

  return (
    <div className="relative">
      {" "}
      <button
        aria-label="User account toggle"
        onClick={handleOpen}
        className=" flex h-[48px] w-[48px] justify-center items-center rounded-full border border-solid
            border-pn-black-base bg-pn-red-base hover:bg-pn-red-hover transition duration-400 ease-in-expo no-close-popover"
      >
        <Image src="/icons/person.svg" alt="" width={32} height={32} />
      </button>
      <UserSettingsPopover />
    </div>
  );
}
