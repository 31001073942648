"use client";

import {
  createUserSettingsPopoverStore,
  type UserSettingsPopoverStore,
} from "@/stores/userSettingsPopoverStore";
import { type ReactNode, createContext, useRef, useContext } from "react";
import { type StoreApi, useStore } from "zustand";

const UserSettingsPopoverStoreContext =
  createContext<StoreApi<UserSettingsPopoverStore> | null>(null);

export type UserSettingsPopoverProviderProps = {
  children: ReactNode;
};

export const UserSettingsPopoverStoreProvider = ({
  children,
}: UserSettingsPopoverProviderProps) => {
  const storeRef = useRef(createUserSettingsPopoverStore());
  if (!storeRef.current) {
    storeRef.current = createUserSettingsPopoverStore();
  }
  return (
    <UserSettingsPopoverStoreContext.Provider value={storeRef.current}>
      {children}
    </UserSettingsPopoverStoreContext.Provider>
  );
};

export const useUserSettingsPopoverStore = <T,>(
  selector: (store: UserSettingsPopoverStore) => T,
): T => {
  const userSettingsPopoverStoreContext = useContext(
    UserSettingsPopoverStoreContext,
  );
  if (!userSettingsPopoverStoreContext) {
    throw new Error(
      "useUserSettingsPopoverStore must be used within a IssueModalStoreProvider",
    );
  }
  return useStore(userSettingsPopoverStoreContext, selector);
};
