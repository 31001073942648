"use client";

import {
  createIssueModalStore,
  type IssueModalStore,
} from "@/stores/issueModalStore";
import { type ReactNode, createContext, useRef, useContext } from "react";
import { type StoreApi, useStore } from "zustand";

const IssueModalStoreContext = createContext<StoreApi<IssueModalStore> | null>(
  null,
);

export type IssueModalStoreProviderProps = {
  children: ReactNode;
};

export const IssueModalStoreProvider = ({
  children,
}: IssueModalStoreProviderProps) => {
  const storeRef = useRef(createIssueModalStore());
  if (!storeRef.current) {
    storeRef.current = createIssueModalStore();
  }
  return (
    <IssueModalStoreContext.Provider value={storeRef.current}>
      {children}
    </IssueModalStoreContext.Provider>
  );
};

export const useIssueModalStore = <T,>(
  selector: (store: IssueModalStore) => T,
): T => {
  const issueModalStoreContext = useContext(IssueModalStoreContext);
  if (!issueModalStoreContext) {
    throw new Error(
      "useIssueModalStore must be used within a IssueModalStoreProvider",
    );
  }
  return useStore(issueModalStoreContext, selector);
};
