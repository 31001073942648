"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";
import {
  applyHeightOnRouteChange,
  initializeSameHeight,
  resizeHeight,
} from "@/lib/sameHeight";
import { withSuspense } from "./hoc/withSuspense";
import { generateBodyClasses } from "@/helpers/generateBodyClasses";

/**
 * You can listen for page changes by composing
 * other Client Component hooks like usePathname and useSearchParams.
 *
 * Useful for running any code on route changes
 *
 * Can be imported to a layout.
 *
 * @link https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
 *
 */
export function NavigationEvents() {
  const pathname = usePathname();

  function checkFlexGap() {
    let flex = document.createElement("div");
    flex.style.display = "flex";
    flex.style.flexDirection = "column";
    flex.style.gap = "1px";

    flex.appendChild(document.createElement("div"));
    flex.appendChild(document.createElement("div"));

    document.body.appendChild(flex);
    let isSupported = flex.scrollHeight === 1; // Check if gap took effect
    flex?.parentNode?.removeChild(flex);

    return isSupported;
  }

  // First time mounted
  useEffect(() => {
    if (!checkFlexGap()) {
      // Apply fallback CSS if gap is not supported
      document.documentElement.classList.add("no-flexbox-gap");
    }

    // Initialize same height functionality only once
    initializeSameHeight();

    // Event listener for window resize
    const handleResize = () => {
      resizeHeight();
    };

    const handleScroll = () => {
      if (window.scrollY >= 300) {
        document.body.classList.add("scrolled");
      } else {
        document.body.classList.remove("scrolled");
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // On Route Change including first mount
  useEffect(() => {
    const splitSlug = pathname.split("/");
    const removeFirstItem = splitSlug.shift();
    const removeLastItem = splitSlug.pop();

    const bodyClasses = generateBodyClasses(splitSlug);

    document.body.classList.add(...bodyClasses);

    // Apply the same height on route changes
    applyHeightOnRouteChange();

    if (!checkFlexGap()) {
      // Apply fallback CSS if gap is not supported
      document.documentElement.classList.add("no-flexbox-gap");
    }

    return () => {
      document.body.className = "max-lg:mt-[80px] lg:mt-[117px]";
    };
  }, [pathname]);

  return null;
}

export default withSuspense(NavigationEvents);
