"use client";

import React, { createContext, useContext, type ReactNode } from "react";
import { usePathname } from "next/navigation";
import { Cart } from "@/types/woocommerce/Cart";

interface CartContextType {
  cartData: Cart | undefined;
  setCartData: React.Dispatch<React.SetStateAction<Cart | undefined>>;
  addToCart: (productId: number) => Promise<void>;
  removeFromCart: (productKey: string) => Promise<void>;
  emptyCart: () => Promise<void>;
}

export const CartContext = createContext<CartContextType>({
  cartData: undefined,
  setCartData: () => {},
  addToCart: async (productId: number) => {},
  removeFromCart: async (productKey: string) => {},
  emptyCart: async () => {},
});

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const [cartData, setCartData] = React.useState<Cart | undefined>();
  const pathname = usePathname();

  const getCart = React.useCallback(async () => {
    if (pathname === "/print-subscription/") {
      try {
        const res = await fetch("/api/v1/cart");
        const data: Cart = await res.json();
        setCartData(data);
      } catch (error) {
        console.error("Failed to fetch cart:", error);
      }
    }
  }, [pathname]);

  // Fetch the cart, anytime it might have changed...
  React.useEffect(() => {
    getCart();
  }, [getCart]);

  const addToCart = async (productId: number) => {
    try {
      const res = await fetch(
        `/api/v1/cart/add-item?productId=${productId}&quantity=1`,
        {
          method: "POST",
        },
      );
      if (!res.ok) throw new Error("Failed to add item to cart");
      await res.json();
      await getCart();
    } catch (error) {
      console.error("Failed to add item to cart:", error);
    }
  };

  const removeFromCart = async (productKey: string) => {
    try {
      const res = await fetch(`/api/v1/cart/remove-item?key=${productKey}`, {
        method: "POST",
      });
      await res.json();
      await getCart();
    } catch (error) {
      console.error("Failed to remove item from cart:", error);
    }
  };

  const emptyCart = async () => {
    try {
      const res = await fetch(`/api/v1/cart/empty-cart`, {
        method: "DELETE",
      });
      await res.json();
      // await getCart();
    } catch (error) {
      console.error("Failed to empty the cart:", error);
    }
  };

  return (
    <CartContext.Provider
      value={{ cartData, setCartData, addToCart, removeFromCart, emptyCart }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext);
