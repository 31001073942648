import { createStore } from "zustand/vanilla";

export type UserSettingsPopoverState = {
  open: boolean;
};

export type UserSettingsPopoverActions = {
  setOpen: (open: boolean) => void;
};

export type UserSettingsPopoverStore = UserSettingsPopoverState &
  UserSettingsPopoverActions;

export const defaultInitialState: UserSettingsPopoverState = {
  open: false,
};

export const createUserSettingsPopoverStore = (
  initalState: UserSettingsPopoverState = defaultInitialState,
) => {
  return createStore<UserSettingsPopoverStore>((set) => ({
    ...initalState,
    setOpen: (open) => set({ open }),
  }));
};
