export function generateBodyClasses(splitSlug: string[]) {
  let classes: string[] = [""];
  let postTypes = ["news", "learning", "inspiration", "reviews"];
  let postTypeClasses = "single-editorial";

  // Top level pages
  if (splitSlug.length === 1 && splitSlug[0] !== "") {
    classes = ["page", `page-${splitSlug[0]}`];
  }

  // Child Pages
  if (splitSlug.length > 1 && !splitSlug.find((el) => postTypes.includes(el))) {
    classes = ["page", "child-page", `page-${splitSlug[splitSlug.length - 1]}`];
  }

  // Frontpage
  if (splitSlug.length < 1) {
    classes = ["page", "post-type-page", "front-page"];
  }

  // Single Post Types
  if (splitSlug.length > 1 && splitSlug.find((el) => postTypes.includes(el))) {
    classes = [
      "post",
      "single",
      `${postTypeClasses}`,
      `single-${splitSlug[0]}`,
      `post-type-${splitSlug[0]}`,
      `${splitSlug[splitSlug.length - 1]}`,
    ];
  }

  return classes;
}
