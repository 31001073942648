import { createStore } from "zustand/vanilla";

export type SignInPopoverState = {
  open: boolean;
};

export type SignInPopoverActions = {
  setOpen: (open: boolean) => void;
};

export type SignInPopoverStore = SignInPopoverState & SignInPopoverActions;

export const defaultInitialState: SignInPopoverState = {
  open: false,
};

export const createSignInPopoverStore = (
  initalState: SignInPopoverState = defaultInitialState,
) => {
  return createStore<SignInPopoverStore>((set) => ({
    ...initalState,
    setOpen: (open) => set({ open }),
  }));
};
