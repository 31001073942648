import React from "react";

/**
 * Wraps a component in a suspense component. Useful for components that
 * use Suspense requiring hooks and methods like useSearchParams and useSWR.
 * @param Component - The component to wrap.
 * @param Fallback - (optional) The fallback component to show while loading.
 * @returns The wrapped component.
 */
export const withSuspense = <P extends object>(
  Component: React.ComponentType<P>,
  Fallback?: React.ComponentType,
): React.FC<P> => {
  return function WithSuspense(props) {
    return (
      <React.Suspense
        fallback={Fallback ? <Fallback /> : <div>Loading...</div>}
      >
        <Component {...props} />
      </React.Suspense>
    );
  };
};
