"use client";

import { useHamburgerMenuStore } from "@/components/providers/HamburgerMenuProvider";
import clsx from "clsx";
import React from "react";
import { SearchBar } from "./SearchBar";
import type { ILinkField, IMenu } from "@/types/wordpress-types";
import Image from "next/image";
import { useSignInPopoverStore } from "@/components/providers/SingInPopoverStoreProvider";
import Link from "next/link";
import { useGetUser } from "@/hooks/useGetUser";

type Props = {
  mainMenuData: IMenu;
  secondaryMenuData: IMenu;
  latestIssueLink: string | ILinkField;
};

export const HamburgerMenu = ({
  mainMenuData,
  secondaryMenuData,
  latestIssueLink,
}: Props) => {
  const { data: userData, isLoading, error } = useGetUser();
  const { open, setOpen } = useHamburgerMenuStore((state) => state);
  const setSignInPopoverOpen = useSignInPopoverStore((state) => state.setOpen);

  return (
    <div
      className={clsx(
        open ? "absolute" : "hidden",
        "lg:hidden w-screen h-screen bg-pn-white-base top-full left-0 border-t border-pn-black-base",
      )}
    >
      <div className="w-full h-[3.125rem] border-b-[3px] border-black bg-pn-off-white-base">
        <div className="wrapper h-full bg-pn-off-white-base border-x border-black">
          <SearchBar placeholder="Search..." formId="mobile-search-form" />
        </div>

        <nav className="wrapper py-5 flex flex-col space-y-1 list-none">
          {mainMenuData.menu_items.map((item) => {
            return (
              <li
                className="w-full h-[2.1875rem] flex justify-center items-center"
                key={`hamburger-${item.ID}`}
                onClick={() => setOpen(false)}
              >
                <Link
                  className="w-full h-full flex items-center justify-center text-center uppercase"
                  href={item.url}
                >
                  <span className="paragraph-bold font-medium text-pn-black-base">
                    {item.title}
                  </span>
                </Link>
              </li>
            );
          })}

          {secondaryMenuData.menu_items.map((item) => {
            return (
              <li
                className="w-full h-[2.1875rem] flex justify-center items-center"
                key={`hamburger-${item.ID}`}
                onClick={() => setOpen(false)}
              >
                <Link
                  className="w-full h-full text-center flex justify-center items-center uppercase"
                  href={item.url}
                >
                  <span className="paragraph-light text-pn-black-base">
                    {item.title}
                  </span>
                </Link>
              </li>
            );
          })}

          {typeof latestIssueLink === "object" && (
            <li className={`w-full h-[3.125rem] !mt-5`}>
              <a
                id="header-read-issue"
                className={`w-full paragraph-bold flex space-x-[0.375rem] h-full btn btn-primary items-center justify-center`}
                href={latestIssueLink?.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  className="inline-block align-middle pr-1"
                  src="/icons/library-white.svg"
                  alt=""
                  width={24}
                  height={24}
                />
                <span>Read the latest issue</span>
              </a>
            </li>
          )}

          {!userData?.user?.name && (
            <li className={`w-full h-[3.125rem] !mt-5`}>
              <button
                className={`w-full paragraph-bold flex space-x-[0.375rem] h-full 
                btn btn-secondary items-center justify-center`}
                onClick={() => {
                  setOpen(false);
                  setSignInPopoverOpen(true);
                }}
              >
                <span>Register / Sign in</span>
              </button>
            </li>
          )}
        </nav>
      </div>
    </div>
  );
};
