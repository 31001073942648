"use client";

import React from "react";

import { UserDesktopButton } from "@/components/buttons/UserDesktopButton";
import { UserIconButton } from "@/components/buttons/UserIconButton";
import { useGetUser } from "@/hooks/useGetUser";

export function UserAccountButtons() {
  const { data: userData, isLoading, error } = useGetUser();

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {/*  Not Logged In button  */}
      {!userData?.user?.name && <UserDesktopButton />}

      {/* Logged In Button */}
      {userData?.user?.name && <UserIconButton />}
    </>
  );
}
