"use client";

import Image from "next/image";
import { usePathname, useRouter, useSearchParams } from "next/navigation";

export function GoogleSignInForm() {
  const router = useRouter();

  const pathname = usePathname();

  const searchParams = useSearchParams();

  const redirect = searchParams.get("redirect");

  const pathToRedirect =
    redirect ?? (pathname.includes("register") ? "/" : pathname);

  const handleClick = async () => {
    const url = await fetch("/api/auth/google/start-auth-flow", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        redirect: pathToRedirect,
      }),
    });

    const { url: authUrl } = await url.json();

    router.push(authUrl);
  };

  return (
    <div className="flex justify-center items-center self-stretch ">
      <button
        className="flex justify-center items-center self-stretch shrink-0 h-[3.125rem] flex-1 space-x-[0.625rem]
          rounded-[1.5625rem] border border-solid border-pn-black-base hover:bg-pn-white-hover transition-all ease-in-expo"
        onClick={handleClick}
      >
        <Image
          src="/icons/google.svg"
          alt="Google"
          width={20}
          height={20}
          sizes="20px"
        />
        <p className="text-pn-black-base paragraph-bold">
          Continue with Google
        </p>
      </button>
    </div>
  );
}
