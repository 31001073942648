import { fetcher } from "@/helpers/swr";
import type { Membership, User } from "@prisma/client";
import useSWR from "swr";

type UserResponse = {
  user?: User & {
    sessionType: "credentials" | "social";
    membership?: Membership;
  };
  error?: string;
};

/**
 * This hook is used to get the current user data (either credential or social)
 */
export function useGetUser() {
  const { data, isLoading, error } = useSWR<UserResponse>(
    "/api/auth/me",
    fetcher,
  );

  return { data, isLoading, error };
}
