"use client";

import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import React from "react";
import Image from "next/image";

const min12Regex = /^.{12,}$/;

const minOneUppercaseRegex = /^(?=.*[A-Z]).*$/;

const minOneLowercaseRegex = /^(?=.*[a-z]).*$/;

const minOneDigitRegex = /^(?=.*\d).*$/;

const minOneSpecialRegex = /^(?=.*[!@#$%^&*()_+{}\[\]|:"<>?~`=\-\\;',.\/]).*$/;

type UpdatePasswordFormProps = {
  email: string;
  code: string;
  resetFlow: () => void;
};

type UpdatePasswordFormValues = {
  newPassword: string;
  newPasswordConfirm: string;
};

type UpdatePasswordResponse = {
  error?: string;
};

const updatePasswordSchema = yup
  .object({
    newPassword: yup
      .string()
      .matches(minOneUppercaseRegex, {
        message: "Password must contain at least one uppercase letter",
      })
      .matches(minOneLowercaseRegex, {
        message: "Password must contain at least one lowercase letter",
      })
      .matches(minOneDigitRegex, {
        message: "Password must contain at least one digit",
      })
      .matches(minOneSpecialRegex, {
        message: "Password must contain at least one special character",
      })
      .matches(min12Regex, {
        message: "Password must be at least 12 characters",
      })
      .required(),
    newPasswordConfirm: yup
      .string()
      .oneOf([yup.ref("newPassword"), ""], "Passwords must match")
      .required("Please retype your password"),
  })
  .required();

export const UpdatePasswordForm = ({
  code,
  email,
  resetFlow,
}: UpdatePasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdatePasswordFormValues>({
    mode: "onChange",
    resolver: yupResolver(updatePasswordSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [apiError, setApiError] = React.useState<string | null>(null);

  const [apiSuccess, setApiSuccess] = React.useState(false);

  const handleApiSuccess = () => {
    setApiSuccess(true);

    setTimeout(() => {
      resetFlow();
    }, 2000);
  };

  const onSubmit: SubmitHandler<UpdatePasswordFormValues> = async (data) => {
    setIsSubmitting(true);

    const setPasswordEndpoint = `/api/auth/set-password`;

    try {
      const response = await fetch(setPasswordEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, code, email }),
      });

      if (response.status >= 400) {
        throw new Error("Network response was not ok");
      }

      if (response.status < 400) {
        const UpdatePasswordResponse =
          (await response.json()) as UpdatePasswordResponse;

        setIsSubmitting(false);

        handleApiSuccess();
      } else {
        console.error("Failed to update password");
        setApiError("Failed to update password");

        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);

      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-start space-y-5 w-full"
    >
      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="newPassword" className="text-pn-black-base">
          Password
        </label>
        <input
          type="password"
          id="retypePassword"
          {...register("newPassword")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`
          )}
        />
      </div>
      {(errors.newPassword || errors.newPasswordConfirm) && (
        <div className="flex p-5 items-center bg-pn-off-white-base text-pn-black-base w-full">
          <Image
            src={"/icons/error-password.svg"}
            alt="error"
            width={20}
            height={20}
            className="mr-1"
          />

          <p className="small-print text-pn-black-base">
            {errors.newPassword?.message ?? errors.newPasswordConfirm?.message}
          </p>
        </div>
      )}

      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="newPasswordConfirm" className="text-pn-black-base">
          Retype password
        </label>
        <input
          type="password"
          id="newPasswordConfirm"
          {...register("newPasswordConfirm")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`
          )}
        />
      </div>

      <button
        type="submit"
        className="w-full flex justify-center items-center h-[3.125rem] rounded-[1.5625rem] border border-pn-black-base text-pn-black-base bg-pn-white-base
          hover:bg-pn-white-hover disabled:bg-pn-white-hover"
        disabled={isSubmitting}
      >
        {apiSuccess ? (
          <div className="flex space-x-[0.625rem]">
            <Image
              src="/icons/success-password.svg"
              alt="success"
              width={20}
              height={20}
            />
            <p className="paragraph-bold text-pn-black-base">
              Password updated
            </p>
          </div>
        ) : (
          <span className="paragraph-bold text-pn-black-base">
            Set new password
          </span>
        )}
      </button>

      {apiError && <p className="text-pn-red-base">{apiError}</p>}
    </form>
  );
};
