"use client";

// External imports
import { useRouter } from "next/navigation";
import React from "react";
import useSWR, { preload } from "swr";
import Image from "next/image";
import { sendGTMEvent } from "@next/third-parties/google";
import { useHamburgerMenuStore } from "@/components/providers/HamburgerMenuProvider";

// Define the fetcher function
const fetcher = (url: string) => fetch(url).then((res) => res.json());

type SearchBarProps = {
  placeholder?: string;
  formId?: string;
};

export function SearchBar({
  placeholder,
  formId = "search-form",
}: SearchBarProps) {
  const router = useRouter();
  const [query, setQuery] = React.useState("");
  const deferredQuery = React.useDeferredValue(query);

  const setHamburgerMenuOpen = useHamburgerMenuStore((state) => state.setOpen);

  // Trigger preload based on the deferred query
  React.useEffect(() => {
    if (deferredQuery && deferredQuery.trim()) {
      const preloadUrl =
        `${process.env.NEXT_PUBLIC_API_URL}/wp-json/bright/v1/search?s=${deferredQuery}` +
        `&page=1&per_page=10&orderby=relevance&order=desc`;
      preload(preloadUrl, fetcher); // SWR Preload Function
    }
  }, [deferredQuery]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
  };

  const submitSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setHamburgerMenuOpen(false);

    if (query && query.replace(/^\s+|\s+$/gm, "")) {
      sendGTMEvent({
        event: "search",
        search_term: query,
      });

      router.push(`/search/?s=${deferredQuery}`);

      // setQuery("")
    }
  };

  return (
    <>
      <form
        action={"/"}
        id={formId}
        onSubmit={submitSearch}
        className="group w-full h-full pl-2.5 cursor-pointer flex transition duration-400 ease-in-expo relative focus-within:bg-white"
      >
        <div className="group-focus-within:hidden w-6 flex items-center justify-center relative h-full">
          <Image
            src="/icons/search.svg"
            alt="Search icon"
            className="align-middle"
            width={24}
            height={24}
          />
        </div>

        <input
          type="search"
          name="q"
          placeholder={
            placeholder ??
            "Search photography technique, the latest gear and breathtaking inspiration…"
          }
          className={`peer bg-transparent focus:placeholder:text-transparent w-full h-full p-1 focus:border-1 focus:border-blue-light rounded outline-none`}
          onChange={handleInputChange}
          value={query}
        />
      </form>
    </>
  );
}
