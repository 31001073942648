"use client";

import {
  createSignInPopoverStore,
  type SignInPopoverStore,
} from "@/stores/signInPopoverStore";
import { type ReactNode, createContext, useRef, useContext } from "react";
import { type StoreApi, useStore } from "zustand";

const SignInPopoverStoreContext =
  createContext<StoreApi<SignInPopoverStore> | null>(null);

export type SignInPopoverProviderProps = {
  children: ReactNode;
};

export const SignInPopoverStoreProvider = ({
  children,
}: SignInPopoverProviderProps) => {
  const storeRef = useRef(createSignInPopoverStore());
  if (!storeRef.current) {
    storeRef.current = createSignInPopoverStore();
  }
  return (
    <SignInPopoverStoreContext.Provider value={storeRef.current}>
      {children}
    </SignInPopoverStoreContext.Provider>
  );
};

export const useSignInPopoverStore = <T,>(
  selector: (store: SignInPopoverStore) => T,
): T => {
  const signInPopoverStoreContext = useContext(SignInPopoverStoreContext);
  if (!signInPopoverStoreContext) {
    throw new Error(
      "useSignInPopoverStore must be used within a IssueModalStoreProvider",
    );
  }
  return useStore(signInPopoverStoreContext, selector);
};
