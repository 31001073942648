"use client";

// External Imports
import React, { SetStateAction } from "react";
import Hamburger from "hamburger-react";
import { useHamburgerMenuStore } from "@/components/providers/HamburgerMenuProvider";
import { useSignInPopoverStore } from "@/components/providers/SingInPopoverStoreProvider";

export function HamburgerButton() {
  const { open, setOpen } = useHamburgerMenuStore((state) => state);
  const setSignInPopoverOpen = useSignInPopoverStore((state) => state.setOpen);

  /**
   * Closes sign in popover if hamburger menu opens up
   * @param openState
   */
  const handleOpen = (openState: boolean) => {
    setOpen(openState);

    if (openState) {
      setSignInPopoverOpen(false);
    }
  };

  return (
    <Hamburger
      toggled={open}
      toggle={handleOpen as React.Dispatch<SetStateAction<boolean>>}
      size={28}
      label="Show mobile menu"
    />
  );
}
