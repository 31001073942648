"use client";

import React from "react";
import { useUserSettingsPopoverStore } from "../providers/UserSettingsPopoverStoreProvider";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useGetUser } from "@/hooks/useGetUser";
import { useSWRConfig } from "swr";

type Props = {};

export const UserSettingsPopover = (props: Props) => {
  const { open, setOpen } = useUserSettingsPopoverStore((state) => state);
  const { data: userData } = useGetUser();
  const popoverRef = React.useRef<HTMLDivElement>(
    null as unknown as HTMLDivElement
  );
  const pathname = usePathname();

  React.useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // For external buttons that open the popover
      // Check if the clicked element has the class 'no-close-popover'
      if (target.closest(".no-close-popover")) {
        return; // Do nothing if the element has the class
      }

      // We only want to close the popover if the user clicks outside the popover
      // on desktop
      if (
        popoverRef.current &&
        !popoverRef.current.contains(target) &&
        window.innerWidth >= 1024
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("mousedown", handleMouseDown);
    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [setOpen]);

  // Close on path changes
  React.useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  // Don't open popover if user is not logged in
  if (!open || !userData?.user) {
    return <></>;
  }

  return (
    <div
      ref={popoverRef}
      className="absolute z-50 w-[9.9375rem] p-[0.125rem] -left-[7.1875rem] top-[2.75rem] bg-pn-white-base shadow-pn flex flex-col space-y-[0.125rem]"
    >
      <Link
        href={"/my-settings"}
        className="flex w-full h-[1.875rem] space-x-[0.375rem] items-center justify-start p-[0.375rem] no-close-popover"
      >
        <Image
          src={"/icons/manage-accounts.svg"}
          alt="Account Settings"
          width={24}
          height={24}
        />
        <span className="paragraph text-pn-black-base">My settings</span>
      </Link>
      <SignOutButton />
    </div>
  );
};

export const SignOutButton = () => {
  const { mutate } = useSWRConfig();
  const { data } = useGetUser();

  const handleLogout = async () => {
    const apiRoute = "/api/auth/logout";

    const response = await fetch(apiRoute, {
      method: "POST",
    });

    await mutate("/api/auth/me");
  };

  return (
    <div className="w-full h-[1.875rem] ">
      <button
        onClick={handleLogout}
        type="button"
        className="flex w-full h-full space-x-[0.375rem] items-center justify-start p-[0.375rem] no-close-popover"
      >
        <Image src={"/icons/logout.svg"} alt="Log out" width={24} height={24} />
        <span className="text-pn-black-base paragraph">Log out</span>
      </button>
    </div>
  );
};
