import { IIssuesFeedResponse } from "@/types/CustomPostTypeFeeds/Issue";
import { createStore } from "zustand/vanilla";
import { IPost } from "@/types/wordpress-types";

export type IssueModalState = {
  issue?: IIssuesFeedResponse | null;
};

export type IssueModalActions = {
  setIssue: (issue?: IIssuesFeedResponse | null) => void;
};

export type IssueModalStore = IssueModalState & IssueModalActions;

export const defaultInitialState: IssueModalState = {
  issue: null,
};

export const createIssueModalStore = (
  initalState: IssueModalState = defaultInitialState,
) => {
  return createStore<IssueModalStore>((set) => ({
    ...initalState,
    setIssue: (issue) => set({ issue }),
  }));
};
