"use client";

import { IUser } from "@/types/User";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import React from "react";
import { Steps } from "./steps";

type ResetPasswordVerificationFormProps = {
  email: string;
  setStep: (step: (typeof Steps)[keyof typeof Steps]) => void;
  setCode: (code: string) => void;
};

type ResetPasswordVerificationFormValues = {
  code: string;
};

type ResetPasswordResponse = {
  error?: string;
  user?: IUser;
};

const resetPasswordVerificationSchema = yup.object({
  code: yup.string().required("Please enter the verification code"),
});

export const ResetPasswordVerificationForm = ({
  email,
  setStep,
  setCode,
}: ResetPasswordVerificationFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordVerificationFormValues>({
    mode: "onSubmit",
    resolver: yupResolver(resetPasswordVerificationSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [apiError, setApiError] = React.useState<string | null>(null);
  const [apiSuccess, setApiSuccess] = React.useState<string | null>(null);

  const handleResendSuccess = async () => {
    setApiSuccess("Reset password email sent");

    setTimeout(() => {
      setApiSuccess(null);
    }, 3000);
  };

  const onSubmit: SubmitHandler<ResetPasswordVerificationFormValues> = async (
    data
  ) => {
    setIsSubmitting(true);

    const resetPasswordEndpoint = `/api/auth/validate-reset-password`;

    try {
      const response = await fetch(resetPasswordEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: data.code, email }),
      });

      // We can store the response in a global state or context and local storage with expiry
      if (response.status < 400) {
        const resetPasswordResponse =
          (await response.json()) as ResetPasswordResponse;

        setIsSubmitting(false);

        setCode(data.code);

        setStep(Steps.updatePassword);
      } else {
        console.error("Failed to validate verification code");
        setApiError("Failed to validate verification code");

        setIsSubmitting(false);
      }
    } catch (error) {
      console.error(error);

      setIsSubmitting(false);
    }
  };

  const handleResend = async () => {
    const resetPasswordEndpoint = `/api/auth/reset-password`;

    try {
      const response = await fetch(resetPasswordEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.status < 400) {
        handleResendSuccess();
      } else {
        console.error("Failed to send reset password request");
        setApiError("Failed to send reset password email");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-start space-y-5 w-full"
    >
      <div className="flex flex-col w-full space-y-[0.625rem]">
        <label htmlFor="email" className="text-pn-black-base">
          Verification Code
        </label>
        <input
          type="text"
          id="code"
          {...register("code")}
          className={clsx(
            `input w-full border px-5 border-pn-black-base bg-white placeholder:text-pn-input-placeholder-base
            text-pn-black-base focus:outline-none focus:outline-pn-input-outline-base focus:-outline-offset-1 h-[3rem]`
          )}
          placeholder="Verification code"
        />
        {errors.code && (
          <p className="text-pn-red-base">{errors.code.message}</p>
        )}
      </div>

      <div className="flex flex-wrap p-5 bg-pn-off-white-base text-pn-black-base w-full">
        <span className="small-print">Didn’t get the email?</span>
        <button
          type="button"
          className="small-print-link px-[2px]"
          onClick={handleResend}
        >
          Send again
        </button>{" "}
        <span className="small-print">or try</span>
        <button
          type="button"
          className="small-print-link px-[2px]"
          onClick={() => {
            setStep(Steps.resetPassword);
          }}
        >
          another address
        </button>
      </div>

      <button
        type="submit"
        className="w-full h-[3.125rem] rounded-[1.5625rem] border border-pn-black-base text-pn-black-base bg-pn-white-base
          hover:bg-pn-white-hover disabled:bg-pn-white-hover"
        disabled={isSubmitting}
      >
        Verify Code
      </button>

      {apiError && <p className="text-pn-red-base">{apiError}</p>}

      {apiSuccess && <p className="text-pn-learning-base">{apiSuccess}</p>}
    </form>
  );
};
